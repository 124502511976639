import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';

import Img from 'gatsby-image';

import settings from '../../settings';

import Reveal from '../../components/Reveal';

export default function() {

	const {
		imageMobile_founder,	imageDesktop_founder,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_founder: file(relativePath: { eq: "pages/about/founder_portrait.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:240) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_founder: file(relativePath: { eq: "pages/about/founder_portrait.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:240) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	  );

	const image_sources = {
		founder: [
			imageMobile_founder.childImageSharp.fluid,
			{
				...imageDesktop_founder.childImageSharp.fluid,
				media: `(min-width: 491px)`,
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<title>About • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/about`} />
				<meta
					name="description"
					content="Blackstone Vermont's company history native to the region, a culture of integrity, and the founder's journey from apprentice to owner."
				/>
			</Helmet>
		  <Banner isH1={true}>About the Company</Banner>
		  <div className="container">
			<div className="row">
			  <div className="col-lg-6 offset-lg-3"style={{paddingTop:'20px'}}>
			  	<Reveal><h3>Synopsis</h3></Reveal>
			  	<p>
				  	Blackstone LLC is a landscaping company incorporated on July 24, 2017 in the State of Vermont.
				  	The organization offers services of stonework, hardscapes, stone veneer, soil drainage, lawn leveling, landscaping, and other services for the Southern Vermont & New Hampshire region.
			  	</p>
			  	<Reveal><h3>Local Culture</h3></Reveal>
			  	<p>
					Although we've grown, there's no job too small for us. We emphasize our support for the community and our humility remains intact as we grow.
			  		As one would expect from a Vermont-based company, we're very much rooted in a culture of integrity and genuineness.
			  		Vermonters hold great pride in their State and as such, we express that pride by producing quality work for our customers.
			  	</p>
				<Reveal><h3>About the Founder</h3></Reveal>
				<p>
					<Img className="shadow" fluid={image_sources.founder} alt="Founder Portrait" align="LEFT" style={{
							borderRadius: '2px',
							float: 'left',
							margin: '4px 20px 10px 0px',
							height: '200px',
							width: '200px',

						}} />
					The founder is a Vermont native, an individual who built relationships in the industry and apprenticed in New England for a number of years before operating as a fully qualified employee for other companies in the region.
					Eventually, the quality of work from this individual garnered market demand for his specific work which became the basis for the transition from being an employee to offering masonry services as a sole proprietor.
					The company now distinguishes itself by focusing on genuineness, integrity, and quality of work above all else.
				</p>
				<h5>Licensing</h5>
				<p style={{fontWeight:'100',}}>
					<a href="https://bizfilings.vermont.gov/online/BusinessInquire/BusinessInformation?businessID=0333048">Vermont | Secretary of State | Business #0333048</a>
				</p>
			  </div>
			</div>
		  </div>
		</Layout>
	);
}
